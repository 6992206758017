// @ts-nocheck
import React, { useLayoutEffect } from 'react';
import { Link, useIntl, injectIntl } from 'gatsby-plugin-intl';
import parse from 'html-react-parser';
import styled from 'styled-components';
import 'animate.css/animate.min.css';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import image from '../images/homepage-background-new.jpg';

const Wrapper = styled.div`
  flex: 1;
  background: url(${image});
  background-size: cover;
  background-position: center;
  box-shadow: rgba(0, 65, 101, 0.78) 0px 0px 0px 2000px inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  display: flex;
  align-self: auto;
  max-width: 1440px;
  margin: auto;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

const H1 = styled.h1`
  & {
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 3.5em;
    animation-delay: ${(props) => `${props.delay}s`};
    margin-bottom: ${({ mb }) => `${mb}em`};
  }
`;

const H2 = styled.h2`
  & {
    color: white;
    animation-delay: ${(props) => `${props.delay}s`};
    font-size: 1.7em;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: ${({ mb }) => `${mb}em`};
  }
`;

const H3 = styled.h3`
  & {
    color: white;
    animation-delay: ${(props) => `${props.delay}s`};
    font-size: 1.2em;
    text-align: center;
    margin-top: 0.25em;
    margin-bottom: ${({ mb }) => `${mb}em`};
  }
`;

const Paragraph = styled.p`
  & {
    text-align: center;
    color: white;
    margin: 0;
    font-style: italic;
    animation-delay: ${(props) => `${props.delay}s`};
    margin-bottom: ${({ mb }) => `${mb}em`};
  }
`;

const RandomText = styled.span`
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  line-height: 40px;

  ${({ bold }) => !!bold && `font-weight: bold`};
  ${({ italics }) => !!italics && `font-style: italic`};
  ${({ underline }) => !!underline && `text-decoration: underline`};
  ${({ size }) => `font-size: ${size}px`};
`

const HR = styled.hr`
  & {
    border-color: white;
    width: 20%;
    animation-delay: ${(props) => `${props.delay}s`};
  }
`;

const Callout = styled(Link)`
  && {
    background: #7e94a2;
    border-color: #7e94a2;
    border-radius: 0px;
    padding: 0.7em 1em;
    align-items: center;
    display: flex;
    margin: 0 auto;
    animation-delay: ${(props) => `${props.delay}s`};
  }
  && svg {
    float: right;
    margin-left: 0.5em;
  }
`;

const DisclaimerText = styled.p`
  color: #004165;
  font-size: 0.8em;
  margin-bottom: 0em;
  font-weight: bold;
  animation-delay: ${(props) => `${props.delay}s`};
`;

const DisclaimerDiv = styled.div`
  display: none;
  @media (min-height: 900px) and (min-width: 768px) {
    display: flex;
    align-self: center;
    position: absolute;
    bottom: 0;
    background: white;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 1em;
    text-align: center;
    align-items: center;
    max-width: 450px;
    animation-delay: ${(props) => `${props.delay}s`};
  }
`;

const DisclaimerImg = styled.img`
  margin: auto;
  display: block;
  padding-bottom: 0.5em;
  animation-delay: ${(props) => `${props.delay}s`};
`;

const IndexPage = () => {
  const intl = useIntl();

  const getZeroOrOne = () => {
    const result = Math.floor(Math.random() * 2)

    return result
  }

  const getRandomNumber = (min, max) => Math.random() * (max - min) + min

  return (
    <Layout style={{ padding: '0px' }}>
      <SEO title="Home" />
      <Wrapper data-style="display: flex;">
        <InnerWrapper>
          <H1 className="animated fadeIn" delay={0.2}>
            {parse(intl.formatMessage({ id: 'homepage.title' }))}
          </H1>
          <H2 className="animated fadeIn" delay={0.5}>
            {intl.formatMessage({ id: 'homepage.subtitle' })}
          </H2>
          <H3 className="animated fadeIn" delay={0.5} mb={1.5}>
            {intl.formatMessage({ id: 'homepage.small_subtitle' })}
          </H3>
          <Paragraph className="animated fadeIn" delay={1} mb={2}>
            {intl.formatMessage({ id: 'homepage.text_1' }).split(',').map(stringPart => (
              <RandomText
                bold={getZeroOrOne()}
                italics={getZeroOrOne()}
                underline={getZeroOrOne()}
                size={getRandomNumber(25, 40)}
              >
                {stringPart}
              </RandomText>
            ))}
          </Paragraph>
          <Paragraph className="animated fadeIn" delay={1.5} mb={0.5}>
            {intl.formatMessage({ id: 'homepage.text_2' })}
          </Paragraph>
          <Paragraph className="animated fadeIn" delay={2} mb={0.5}>
            {intl.formatMessage({ id: 'homepage.text_3' })}
          </Paragraph>
          <Paragraph className="animated fadeIn" delay={2.5} mb={0.5}>
            {intl.formatMessage({ id: 'homepage.text_4' })}
          </Paragraph>
          <HR className="animated fadeIn" delay={2} />
          <Callout
            to="/program-and-pricing"
            className="btn btn-primary animated fadeIn"
            delay={2.3}
          >
            {intl.formatMessage({ id: 'homepage.contact_text' })}{' '}
            <i className="fas fa-chevron-right" />
          </Callout>
          <DisclaimerDiv className="animated fadeIn" delay={2.5}>
            <DisclaimerImg
              src="https://res.cloudinary.com/mystay-international/image/upload/c_scale,w_110/v1534983234/Logos/MyStay-logo-1.jpg"
              alt="Mystay International"
              className="animated fadeIn"
              delay={2.7}
            />
            <DisclaimerText
              dangerouslySetInnerHTML={{
                __html: intl.formatHTMLMessage({
                  id: 'homepage.footer_text',
                }),
              }}
              className="animated fadeIn"
              delay={2.8}
            />
          </DisclaimerDiv>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(IndexPage);
